import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import slugify from "slugify";
import _ from "lodash";
import { SelectBox, TextInput } from "../../components/Form/Form";
import { getCategorys, addCategory } from "../../actions/categories";
const AddBrand = ({
  addCategory,
  getCategorys,
  alert,
  history,
  category: { categories },
}) => {
  const [slug, setSlug] = useState("");
  const [slugError, setSlugError] = useState(false);
  useEffect(() => {
    getCategorys();
  }, []);

  const [pic, setPic] = useState(null);
  useEffect(() => {
    setSlugError(false);

    if (categories) {
      const newData = _.filter(categories, { slug: slug });

      const subData = _.filter(categories, { subcategories: [{ slug: slug }] });

      const subsubData = _.filter(categories, {
        subcategories: [{ subcategory: [{ slug: slug }] }],
      });

      if (newData && newData.length > 0) {
        setSlugError(true);
      }
      if (subData && subData.length > 0) {
        setSlugError(true);
      }
      if (subsubData && subsubData.length > 0) {
        setSlugError(true);
      }
    }
  }, [slug, categories]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Category</title>
      </Helmet>
      <Sidebar />
      <Navbar />

      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb
                title="Add Category"
                link_title="Category"
                link_URI="/categories"
              />

              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Add Category</h5>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          name: "",
                          slug: "",
                          category: "",
                          subcategory: "",
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);

                          const formData = {
                            name: values.name,
                            slug: values.slug,
                            category: values.category,
                            subcategory: values.subcategory,
                          };
                          const newForm = new FormData();
                          newForm.append("data", JSON.stringify(formData));

                          if (pic) {
                            newForm.append("files", pic, pic.name);
                          }
                          await addCategory(newForm, formData, categories);
                          resetForm();
                          setPic(null);
                          history.push(`/categories`);
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <label> Name </label>
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="Name"
                                    value={formik.values.name}
                                    onBlur={() => setSlug(formik.values.slug)}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "name",
                                        e.target.value
                                      );
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(
                                          e.target.value.toLocaleLowerCase()
                                        )
                                      );
                                    }}
                                  />
                                  {formik.errors.name &&
                                    formik.touched.name && (
                                      <p className="text-danger">
                                        {formik.errors.name}
                                      </p>
                                    )}
                                </div>
                                <div className="col-md-6">
                                  <label>Category Image </label>
                                  <input
                                    type="file"
                                    name="singature"
                                    className="form-control-file"
                                    onChange={(event) =>
                                      setPic(event.target.files[0])
                                    }
                                  />
                                </div>

                                <div className="col-md-6">
                                  <label> Parent Category </label>

                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "category",
                                        e.target.value
                                      );
                                      setSelectedCategory(e.target.value);
                                    }}
                                  >
                                    <option></option>
                                    {categories &&
                                      categories.map((item, index) => {
                                        return (
                                          <option value={index}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-md-6">
                                  <SelectBox
                                    label="Category"
                                    name="subcategory"
                                    placeholder="Enter Category"
                                  >
                                    <option value=""> Select Category </option>
                                    {categories &&
                                      selectedCategory &&
                                      selectedCategory !== "" &&
                                      categories[selectedCategory] &&
                                      categories[
                                        selectedCategory
                                      ].subcategories.map((item, index) => {
                                        return (
                                          <option value={index}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </SelectBox>
                                </div>

                                <div className="col-md-6">
                                  <label>Slug </label>
                                  <input
                                    type="text"
                                    name="slug"
                                    className="form-control"
                                    placeholder="Slug"
                                    value={formik.values.slug}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(
                                          e.target.value.toLocaleLowerCase()
                                        )
                                      )
                                    }
                                  />
                                  {slugError && (
                                    <p className="text-danger">
                                      already taken should unique
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Fee"
                                    name="commision"
                                    type="text"
                                    placeholder="Enter Fee"
                                  />
                                </div>
                                {alert &&
                                  alert.map((item) => {
                                    return (
                                      <p className={`text-${item.alertType}`}>
                                        {item.msg}
                                      </p>
                                    );
                                  })}
                                <div className="col-md-12 mt-3">
                                  <button
                                    className="btn btn-primary"
                                    disabled={slugError}
                                  >
                                    {formik.isSubmitting ? "Saving..." : "Save"}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  category: state.category,
});

const mapDispatchToProps = { addCategory, getCategorys };

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
