import api from "../utils/api";
import {
  GET_CUSTOMERS,
  TOTAL_CUSTOMERS,
  ADD_CUSTOMER,
  GET_CUSTOMER,
  CUSTOMERS_ERROR,
} from "./types";

// Get posts
export const countCustomer = (searchParams) => async (dispatch) => {
  try {
    let query = "/users/count?role.id_nin=602a4f84ef33850e407f5cb6";
    let newQ = ``;
    searchParams &&
      searchParams.map((item, index) => {
        if (index === 0) {
          newQ += `&${item.label}=${item.value}`;
        } else {
          newQ += `&${item.label}=${item.value}`;
        }
      });
    console.log("Query", newQ);
    query = query + newQ;
    console.log(query);
    let res = await api.get(query);
    dispatch({
      type: TOTAL_CUSTOMERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: CUSTOMERS_ERROR,
      payload: err,
    });
  }
};

// Get posts
export const getCustomers = (page, searchParams) => async (dispatch) => {
  try {
    let start = 0;
    let limit = 10;
    if (page) {
      start = (page - 1) * 10;
    }
    let query =
      "/users?role.id_nin=602a4f84ef33850e407f5cb6&_limit=" +
      limit +
      "&_start=" +
      start +
      "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get(query);

    dispatch({
      type: GET_CUSTOMERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CUSTOMERS_ERROR,
      payload: err,
    });
  }
};
// Get posts
export const getCustomer = (id) => async (dispatch) => {
  try {
    const res = await api.get("/users/" + id);

    dispatch({
      type: GET_CUSTOMER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CUSTOMERS_ERROR,
      payload: err,
    });
  }
};

export const addCustomer = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/users`, data);

    dispatch({
      type: ADD_CUSTOMER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CUSTOMERS_ERROR,
      payload: err,
    });
  }
};
export const editCustomer = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/users/" + id, data);

    dispatch(getCustomer(id));
  } catch (err) {
    dispatch({
      type: CUSTOMERS_ERROR,
      payload: err,
    });
  }
};
