import React, { useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import { addCategory } from "../../actions/categories";
const AddBrand = ({ addCategory, alert, history }) => {
  const [pic, setPic] = useState(null);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Category</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb
                title="Add Category"
                link_title="Category"
                link_URI="/categories"
              />

              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Add Category</h5>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          name: "",
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          console.log("Submitted");
                          const formData = {
                            name: values.name,
                          };
                          const newForm = new FormData();
                          newForm.append("data", JSON.stringify(formData));

                          if (pic) {
                            newForm.append("files.pic", pic, pic.name);
                          }
                          await addCategory(newForm);
                          resetForm();
                          setPic(null);
                          history.push(`/categories`);
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Category Image </label>
                                  <input
                                    type="file"
                                    name="singature"
                                    className="form-control-file"
                                    onChange={(event) =>
                                      setPic(event.target.files[0])
                                    }
                                  />
                                </div>
                                {alert &&
                                  alert.map((item) => {
                                    return (
                                      <p className={`text-${item.alertType}`}>
                                        {item.msg}
                                      </p>
                                    );
                                  })}
                                <div className="col-md-12 mt-3">
                                  <button className="btn btn-primary">
                                    {formik.isSubmitting ? "Saving..." : "Save"}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { addCategory };

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
