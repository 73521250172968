import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import slugify from "slugify";

import { addZipcode, getZipcodes } from "../../actions/zipcodes";
const AddBrand = ({
  addZipcode,
  getZipcodes,
  alert,
  history,
  zipcode: { zipcodes },
}) => {
  useEffect(() => {
    getZipcodes();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Pincode</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb
                title="Add Pincode"
                link_title="Pincodes"
                link_URI="/pincodes"
              />

              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Add Pincode</h5>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          name: "",
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          zipcodes.push(values.name);

                          await addZipcode(zipcodes);
                          resetForm();

                          history.push(`/pincodes`);
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Pincode </label>
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="Pincode"
                                    value={formik.values.name}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "name",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {formik.errors.name &&
                                    formik.touched.name && (
                                      <p className="text-danger">
                                        {formik.errors.name}
                                      </p>
                                    )}
                                </div>

                                {alert &&
                                  alert.map((item) => {
                                    return (
                                      <p className={`text-${item.alertType}`}>
                                        {item.msg}
                                      </p>
                                    );
                                  })}
                                <div className="col-md-12 mt-3">
                                  <button className="btn btn-primary">
                                    {formik.isSubmitting ? "Saving..." : "Save"}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  zipcode: state.zipcode,
});

const mapDispatchToProps = { addZipcode, getZipcodes };

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
