import api from "../utils/api";
export const upload = async (data) => {
  try {
    const res = await api.post(`/upload`, data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const createVariation = async (data) => {
  try {
    const res = await api.post(`/variations`, { notes: data });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
