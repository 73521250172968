import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput, SelectBox } from "../../components/Form/Form";
import { getOrder, editOrder } from "../../actions/orders";
import SingleElement from "../../components/SingelItem";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const SingleBrand = ({ match, getOrder, editOrder, order: { order } }) => {
  useEffect(() => {
    getOrder(match.params.id);
  }, [match.params]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>View Order</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      {order && (
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <Breadcrumb
                  title="View order"
                  link_title="Orders"
                  link_URI="/orders"
                />

                <div className="row">
                  <div className="col-md-9">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">View Order</h5>
                        <Link to={`/orders/${order._id}/edit`}>
                          <i className="fa fa-edit"></i> Edit
                        </Link>
                      </div>
                      <div className="card-body">
                        <p>
                          <strong> Deliver To </strong>
                        </p>
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              style={{
                                background: "#ffecec",
                                padding: "10px 10px",
                              }}
                            >
                              <strong>
                                <i className="fa fa-home"></i>{" "}
                                {order.customer && order.customer.first_name}{" "}
                                {order.customer && order.customer.last_name}
                              </strong>
                              <br />
                              <strong>
                                <i className="fa fa-phone"></i>{" "}
                                {order.customer && order.customer.phone}
                              </strong>

                              <p style={{ padding: "10px" }}>
                                {order.address &&
                                  `${order.address.address_1} ${order.address.address_2}  ${order.address.landmark} ${order.address.city}`}
                              </p>
                              <p style={{ padding: "10px" }}>
                                {order.address && `${order.address.zipcode} `}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              style={{
                                background: "#eafffd",
                                padding: "10px 10px",
                              }}
                            >
                              <strong>
                                <i className="fa fa-user"></i> Order Status
                              </strong>
                              <br />
                              <h3> {order.order_status} </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <p>
                          <strong> Products </strong>
                        </p>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Image</th>
                              <th>Name</th>
                              <th>Quantity</th>
                              <th>Amount</th>
                              <th>Sub Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.product &&
                              order.product.map((item, index) => {
                                return (
                                  <tr>
                                    <td> {index + 1} </td>
                                    <td>
                                      {item.featured_image ? (
                                        <img
                                          src={`${URI}${item.featured_image}`}
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "contain",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={
                                            "/images/product-placeholder.jpg"
                                          }
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "contain",
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {item.name} - {item.value}
                                    </td>
                                    <td>{item.quantity}</td>
                                    <td>₹{item.sale_price}</td>
                                    <td>₹{item.quantity * item.sale_price}</td>
                                  </tr>
                                );
                              })}
                            <tr style={{ background: "#eafffd" }}>
                              <td colSpan={5} style={{ textAlign: "right" }}>
                                Sub Total
                              </td>
                              <td colSpan={1}> ₹{order.product_total} </td>
                            </tr>
                            <tr>
                              <td colSpan={5} style={{ textAlign: "right" }}>
                                Discounts
                              </td>
                              <td colSpan={1}> ₹{order.coupon_discount} </td>
                            </tr>

                            <tr style={{ background: "#ffecec" }}>
                              <td colSpan={5} style={{ textAlign: "right" }}>
                                Total
                              </td>
                              <td colSpan={1}> ₹{order.total_amount} </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-md-6">
                            <p>
                              <strong>Coupon</strong>
                            </p>
                            {order && order.coupon && (
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th> Coupon Code </th>
                                    <th> Total Discount </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{order.coupon.code}</td>
                                    <td> ₹{order.coupon_discount} </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <div className="card-header"> Status </div>
                      <Formik
                        initialValues={{
                          order_status: "",
                        }}
                        validationSchema={Yup.object({
                          order_status: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          console.log("Submitted");
                          console.log(values);
                          await editOrder(order._id, values);
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="p-3">
                                <div>
                                  <SelectBox
                                    label="Order Status"
                                    name="order_status"
                                    placeholder="Enter Order Status"
                                  >
                                    <option value="">
                                      {" "}
                                      Select Order Status{" "}
                                    </option>
                                    <option value="pending"> Pending </option>
                                    <option value="processing">
                                      {" "}
                                      Processing{" "}
                                    </option>
                                    <option value="on_hold"> On Hold </option>
                                    <option value="completed">
                                      {" "}
                                      Completed{" "}
                                    </option>
                                    <option value="cancelled">
                                      {" "}
                                      Cancelled{" "}
                                    </option>
                                    <option value="refunded"> Refunded </option>
                                    <option value="failed"> Failed </option>
                                  </SelectBox>
                                </div>
                                <div>
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    {" "}
                                    {formik.isSubmitting
                                      ? "Saving..."
                                      : "Change Status"}{" "}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                    <div className="card">
                      <div className="card-header"> Progress </div>
                      <div className="card-body">
                        <ul className="timeline">
                          <li className="timeline-inverted">
                            <div className="timeline-badge success">
                              <i className="fa fa-check" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-body">
                                <p>
                                  <strong> Order Placed </strong>
                                  <br />
                                  {order.order_date}
                                </p>
                              </div>
                            </div>
                          </li>
                          {/* <li className="timeline-inverted">
                            <div className="timeline-badge success">
                              <i className="fa fa-check" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-body">
                                <p>
                                  <strong> Order Processed </strong>
                                  <br />
                                  26 Jan 2022
                                </p>
                              </div>
                            </div>
                          </li> */}
                          {/* <li className="timeline-inverted">
                            <div className="timeline-badge success">
                              <i className="fa fa-check" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-body">
                                <p>
                                  <strong> Order Dispatched </strong>
                                  <br />
                                  26 Jan 2022
                                </p>
                              </div>
                            </div>
                          </li> */}
                          {/* <li className="timeline-inverted">
                            <div className="timeline-badge success">
                              <i className="fa fa-check" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-body">
                                <p>
                                  <strong>Order Delivered </strong>
                                  <br />
                                  26 Jan 2022
                                </p>
                              </div>
                            </div>
                          </li> */}
                          {/* <li className="timeline-inverted">
                            <div className="timeline-badge danger">
                              <i className="fa fa-close" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-body">
                                <p>
                                  <strong> Order Returned </strong>
                                  <br />
                                  26 Jan 2022
                                </p>
                              </div>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ order: state.order });

const mapDispatchToProps = { getOrder, editOrder };

export default connect(mapStateToProps, mapDispatchToProps)(SingleBrand);
