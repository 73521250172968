import React from "react";
import { Route, Switch } from "react-router-dom";
import AddBrand from "./Pages/brands/AddBrand";
import AllBrands from "./Pages/brands/AllBrands";

import AddCategory from "./Pages/categories/AddCategory";
import AllCategories from "./Pages/categories/AllCategories";
import EditCategory from "./Pages/categories/EditCategory";
import SingleCategory from "./Pages/categories/SingleCategory";
import AddCoupon from "./Pages/coupons/AddCoupon";
import AllCoupons from "./Pages/coupons/AllCoupons";
import EditCoupon from "./Pages/coupons/EditCoupon";
import SingleCoupon from "./Pages/coupons/SingleCoupon";
import AddCustomer from "./Pages/customers/AddCustomer";
import AllCustomers from "./Pages/customers/AllCustomers";
import EditCustomer from "./Pages/customers/EditCustomer";
import SingleCustomer from "./Pages/customers/SingleCustomer";
import Dashboard from "./Pages/Dashboard";
import Home from "./Pages/Home";
import AddOrder from "./Pages/orders/AddOrder";
import AllOrders from "./Pages/orders/AllOrders";
import EditOrder from "./Pages/orders/EditOrder";
import SingleOrder from "./Pages/orders/SingleOrder";
import AddPincode from "./Pages/pincode/AddPincode";
import AllPincodes from "./Pages/pincode/AllPincodes";
import AddProduct from "./Pages/products/AddProduct";
import AllProducts from "./Pages/products/AllProducts";
import EditProduct from "./Pages/products/EditProduct";
import SingleProduct from "./Pages/products/SingleProduct";
import BeforeLoginRoutes from "./Routes/BeforeLoginRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";

function Routes() {
  return (
    <Switch>
      <BeforeLoginRoutes exact path="/" component={Home} />
      <PrivateRoutes exact path="/dashboard" component={Dashboard} />
      <PrivateRoutes exact path="/brands" component={AllBrands} />

      <PrivateRoutes exact path="/brands/add" component={AddBrand} />
      <PrivateRoutes exact path="/categories" component={AllCategories} />
      <PrivateRoutes
        exact
        path="/categories/:id/view"
        component={SingleCategory}
      />
      <PrivateRoutes
        exact
        path="/categories/:id/edit"
        component={EditCategory}
      />
      <PrivateRoutes exact path="/categories/add" component={AddCategory} />
      <PrivateRoutes exact path="/coupons" component={AllCoupons} />
      <PrivateRoutes exact path="/coupons/:id/view" component={SingleCoupon} />
      <PrivateRoutes exact path="/coupons/:id/edit" component={EditCoupon} />
      <PrivateRoutes exact path="/coupons/add" component={AddCoupon} />
      <PrivateRoutes exact path="/products" component={AllProducts} />
      <PrivateRoutes
        exact
        path="/products/:id/view"
        component={SingleProduct}
      />
      <PrivateRoutes exact path="/products/:id/edit" component={EditProduct} />
      <PrivateRoutes exact path="/products/add" component={AddProduct} />
      <PrivateRoutes exact path="/customers" component={AllCustomers} />
      <PrivateRoutes
        exact
        path="/customers/:id/view"
        component={SingleCustomer}
      />
      <PrivateRoutes
        exact
        path="/customers/:id/edit"
        component={EditCustomer}
      />
      <PrivateRoutes exact path="/customers/add" component={AddCustomer} />
      <PrivateRoutes exact path="/orders" component={AllOrders} />
      <PrivateRoutes exact path="/orders/:id/view" component={SingleOrder} />
      <PrivateRoutes exact path="/orders/:id/edit" component={EditOrder} />
      <PrivateRoutes exact path="/orders/add" component={AddOrder} />
      <PrivateRoutes exact path="/pincodes" component={AllPincodes} />
      <PrivateRoutes exact path="/pincodes/add" component={AddPincode} />
    </Switch>
  );
}

export default Routes;
