import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../components/Form/Form";
import { login } from "../actions/auth";

const Home = ({ login, alert }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <div className="auth-wrapper">
        <div className="auth-content text-center">
          <div className="borderless card">
            <div className="card-body">
              <h3> Login </h3>
              <Formik
                initialValues={{
                  identifier: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  identifier: Yup.string().required("Required"),
                  password: Yup.string().required("Required"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  await login(values);
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="form-group mb-3">
                        <div>
                          <TextInput
                            name="identifier"
                            type="text"
                            placeholder="Enter Email"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <div>
                          <TextInput
                            name="password"
                            type="password"
                            placeholder="Enter Password "
                          />
                        </div>
                      </div>
                      {alert &&
                        alert.map((item) => {
                          return <p className="text-danger"> {item.msg}</p>;
                        })}
                      <div className="row">
                        <div mt={2} className="col">
                          <button
                            color="primary"
                            type="submit"
                            className="btn-block btn btn-primary btn-large"
                          >
                            Signin
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
