export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
// Volunteer
export const ADD_VOLUNTEER_SUCCESS = "ADD_VOLUNTEER_SUCCESS";
export const LIST_VOLUNTEERS = "LIST_VOLUNTEERS";
export const TOTAL_VOLUNTEERS = "TOTAL_VOLUNTEERS";

export const VIEW_VOLUNTEER = "VIEW_VOLUNTEER";
export const VOLUNTEER_ERROR = "VOLUNTEER_ERROR";
export const ADD_VOLUNTEER = "ADD_VOLUNTEER";
// Students
export const ADD_STUDENT = "ADD_STUDENT";
export const TOTAL_STUDENT = "TOTAL_STUDENT";
export const LIST_STUDENTS = "LIST_STUDENTS";
export const GET_STUDENT = "GET_STUDENT";
export const EDIT_STUDENT = "EDIT_STUDENT";
export const STUDENT_ERROR = "STUDENT_ERROR";
// Scribe Requests
export const ADD_SCRIBE = "ADD_SCRIBE";
export const TOTAL_SCRIBE = "TOTAL_SCRIBE";
export const LIST_SCRIBES = "LIST_SCRIBES";
export const LIST_SCRIBES_BY_VOLUNTEER = "LIST_SCRIBES_BY_VOLUNTEER";
export const LIST_SCRIBES_BY_STUEDNT = "LIST_SCRIBES_BY_STUEDNT";
export const GET_SCRIBE = "GET_SCRIBE";
export const EDIT_SCRIBE = "EDIT_SCRIBE";
export const SCRIBE_ERROR = "SCRIBE_ERROR";
// Projects
export const ADD_PROJECT = "ADD_PROJECT";
export const TOTAL_PROJECT = "TOTAL_PROJECT";
export const LIST_PROJECTS = "LIST_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const PROJECT_ERRORS = "PROJECT_ERRORS";
export const CLEAR_PROJECT = "CLEAR_PROJECT";

export const GET_BRANDS = "GET_BRANDS";
export const TOTAL_BRANDS = "TOTAL_BRANDS";
export const ADD_BRAND = "ADD_BRAND";
export const EDIT_BRAND = "EDIT_BRAND";
export const GET_BRAND = "GET_BRAND";
export const BRANDS_ERROR = "BRANDS_ERROR";

export const GET_CATEGORYS = "GET_CATEGORYS";
export const TOTAL_CATEGORYS = "TOTAL_CATEGORYS";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const CATEGORYS_ERROR = "CATEGORYS_ERROR";
// Types
export const GET_COUPONS = "GET_COUPONS";
export const TOTAL_COUPONS = "TOTAL_COUPONS";
export const ADD_COUPON = "ADD_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const GET_COUPON = "GET_COUPON";
export const COUPONS_ERROR = "COUPONS_ERROR";

//
export const GET_PRODUCTS = "GET_PRODUCTS";
export const TOTAL_PRODUCTS = "TOTAL_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const GET_PRODUCT = "GET_PRODUCT";
export const RESET_PRODUCT = "RESET_PRODUCT";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const TOTAL_CUSTOMERS = "TOTAL_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const CUSTOMERS_ERROR = "CUSTOMERS_ERROR";

export const GET_ORDERS = "GET_ORDERS";
export const TOTAL_ORDERS = "TOTAL_ORDERS";
export const ADD_ORDER = "ADD_ORDER";
export const EDIT_ORDER = "EDIT_ORDER";
export const GET_ORDER = "GET_ORDER";
export const ORDERS_ERROR = "ORDERS_ERROR";

export const GET_ZIPCODES = "GET_ZIPCODES";
export const TOTAL_ZIPCODES = "TOTAL_ZIPCODES";
export const ADD_ZIPCODE = "ADD_ZIPCODE";
export const EDIT_ZIPCODE = "EDIT_ZIPCODE";
export const GET_ZIPCODE = "GET_ZIPCODE";
export const ZIPCODES_ERROR = "ZIPCODES_ERROR";
