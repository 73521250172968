import api from "../utils/api";
import { setAlert } from "./alert";
import {
  GET_BRANDS,
  TOTAL_BRANDS,
  ADD_BRAND,
  GET_BRAND,
  BRANDS_ERROR,
} from "./types";
import { upload } from "./functions";
// Get posts
export const getBrands = (page, searchParams) => async (dispatch) => {
  try {
    let start = 0;
    let limit = 10;
    if (page) {
      start = (page - 1) * 10;
    }
    let query =
      "/brands?_limit=" + limit + "&_start=" + start + "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get("/brand");
    if (res.data) {
      dispatch({
        type: GET_BRANDS,
        payload: res.data.brands,
      });
    }
  } catch (err) {
    dispatch({
      type: BRANDS_ERROR,
      payload: err,
    });
  }
};

export const addBrand = (data, formData, brands) => async (dispatch) => {
  try {
    const res = await upload(data);
    const images = res;

    if (images && images.length > 0) {
      formData.pic = images[0].url;
    }
    brands.push(formData);
    console.log(brands);

    const res1 = await api.put(`/brand`, { brands: brands });

    dispatch({
      type: ADD_BRAND,
      payload: res1.data,
    });
    dispatch(setAlert("Saved Successfully", "success"));
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
  }
};
export const editBrand = (data) => async (dispatch) => {
  try {
    const res = await api.put("/brand", { brands: data });
  } catch (err) {
    dispatch({
      type: BRANDS_ERROR,
      payload: err,
    });
  }
};
