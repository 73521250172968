import api from "../utils/api";
import {
  GET_COUPONS,
  TOTAL_COUPONS,
  ADD_COUPON,
  GET_COUPON,
  COUPONS_ERROR,
} from "./types";

// Get posts
export const countCoupon = (searchParams) => async (dispatch) => {
  try {
    let query = "/coupons/count";
    let newQ = ``;
    searchParams.map((item, index) => {
      if (index === 0) {
        newQ += `?${item.label}=${item.value}`;
      } else {
        newQ += `&${item.label}=${item.value}`;
      }
    });
    console.log("Query", newQ);
    query = query + newQ;
    let res = await api.get(query);
    dispatch({
      type: TOTAL_COUPONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COUPONS_ERROR,
      payload: err,
    });
  }
};

// Get posts
export const getCoupons = (page, searchParams) => async (dispatch) => {
  try {
    let start = 0;
    let limit = 10;
    if (page) {
      start = (page - 1) * 10;
    }
    let query =
      "/coupons?_limit=" + limit + "&_start=" + start + "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get(query);

    dispatch({
      type: GET_COUPONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COUPONS_ERROR,
      payload: err,
    });
  }
};
// Get posts
export const getCoupon = (id) => async (dispatch) => {
  try {
    const res = await api.get("/coupons/" + id);

    dispatch({
      type: GET_COUPON,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COUPONS_ERROR,
      payload: err,
    });
  }
};

export const addCoupon = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/coupons`, data);

    dispatch({
      type: ADD_COUPON,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COUPONS_ERROR,
      payload: err,
    });
  }
};
export const editCoupon = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/coupons/" + id, data);

    dispatch(getCoupon(id));
  } catch (err) {
    dispatch({
      type: COUPONS_ERROR,
      payload: err,
    });
  }
};
