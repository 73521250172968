import api from "../utils/api";
import {
  GET_ORDERS,
  TOTAL_ORDERS,
  ADD_ORDER,
  GET_ORDER,
  ORDERS_ERROR,
} from "./types";

// Get posts
export const countOrder = (searchParams) => async (dispatch) => {
  try {
    let query = "/orders/count";
    let newQ = ``;
    searchParams.map((item, index) => {
      if (index === 0) {
        newQ += `?${item.label}=${item.value}`;
      } else {
        newQ += `&${item.label}=${item.value}`;
      }
    });
    console.log("Query", newQ);
    query = query + newQ;
    let res = await api.get(query);
    dispatch({
      type: TOTAL_ORDERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err,
    });
  }
};

// Get posts
export const getOrders = (page, searchParams) => async (dispatch) => {
  try {
    let start = 0;
    let limit = 10;
    if (page) {
      start = (page - 1) * 10;
    }
    let query =
      "/orders?_limit=" + limit + "&_start=" + start + "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get(query);

    dispatch({
      type: GET_ORDERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err,
    });
  }
};
// Get posts
export const getOrder = (id) => async (dispatch) => {
  try {
    const res = await api.get("/orders/" + id);

    dispatch({
      type: GET_ORDER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err,
    });
  }
};

export const addOrder = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/orders`, data);

    dispatch({
      type: ADD_ORDER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err,
    });
  }
};
export const editOrder = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/orders/" + id, data);

    dispatch(getOrder(id));
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err,
    });
  }
};
