import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import { getCategory, editCategory } from "../../actions/categories";
import SingleElement from "../../components/SingelItem";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const SingleBrand = ({
  match,
  getCategory,
  editCategory,
  history,
  category: { category },
  alert,
}) => {
  const [pic, setPic] = useState(null);
  useEffect(() => {
    getCategory(match.params.id);
  }, [match.params]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>View Brand</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      {category && (
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <Breadcrumb
                  title="Edit Category"
                  link_title="Categories"
                  link_URI="/categories"
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Edit Category</h5>
                        <Link to={`/categories/${category._id}/edit`}>
                          <i className="fa fa-edit"></i> Edit
                        </Link>
                      </div>
                      <div className="card-body">
                        <Formik
                          initialValues={{
                            name: category.name,
                          }}
                          validationSchema={Yup.object({
                            name: Yup.string().required("Required"),
                          })}
                          onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                          ) => {
                            setSubmitting(true);
                            console.log("Submitted");
                            const formData = {
                              name: values.name,
                            };
                            const newForm = new FormData();
                            newForm.append("data", JSON.stringify(formData));

                            if (pic) {
                              newForm.append("files.pic", pic, pic.name);
                            }
                            await editCategory(category._id, newForm);
                            resetForm();
                            setPic(null);
                            history.push(`/categories/${category._id}/view`);
                            setSubmitting(false);
                          }}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="row">
                                  <div className="col-md-6">
                                    <TextInput
                                      label="Name"
                                      name="name"
                                      type="text"
                                      placeholder="Enter Name"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label>Brand Image </label>
                                    <input
                                      type="file"
                                      name="singature"
                                      className="form-control-file"
                                      onChange={(event) =>
                                        setPic(event.target.files[0])
                                      }
                                    />
                                  </div>
                                  {alert &&
                                    alert.map((item) => {
                                      return (
                                        <p className={`text-${item.alertType}`}>
                                          {item.msg}
                                        </p>
                                      );
                                    })}
                                  <div className="col-md-12 mt-3">
                                    <button className="btn btn-primary">
                                      {formik.isSubmitting
                                        ? "Saving..."
                                        : "Save"}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  category: state.category,
  alert: state.alert,
});

const mapDispatchToProps = { getCategory, editCategory };

export default connect(mapStateToProps, mapDispatchToProps)(SingleBrand);
