import api from "../utils/api";
import {
  GET_CATEGORYS,
  TOTAL_CATEGORYS,
  ADD_CATEGORY,
  GET_CATEGORY,
  CATEGORYS_ERROR,
} from "./types";
import { upload } from "./functions";
// Get posts
export const countCategory = (searchParams) => async (dispatch) => {
  try {
    let query = "/categories/count";
    let newQ = ``;
    searchParams.map((item, index) => {
      if (index === 0) {
        newQ += `?${item.label}=${item.value}`;
      } else {
        newQ += `&${item.label}=${item.value}`;
      }
    });
    console.log("Query", newQ);
    query = query + newQ;
    let res = await api.get(query);
    dispatch({
      type: TOTAL_CATEGORYS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CATEGORYS_ERROR,
      payload: err,
    });
  }
};

// Get posts
export const getCategorys = (page, searchParams) => async (dispatch) => {
  try {
    let start = 0;
    let limit = 10;
    if (page) {
      start = (page - 1) * 10;
    }
    let query =
      "/categories?_limit=" +
      limit +
      "&_start=" +
      start +
      "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get("/category");
    if (res.data) {
      dispatch({
        type: GET_CATEGORYS,
        payload: res.data.main_categories,
      });
    }
  } catch (err) {
    dispatch({
      type: CATEGORYS_ERROR,
      payload: err,
    });
  }
};
// Get posts
export const getCategory = (id) => async (dispatch) => {
  try {
    const res = await api.get("/categories/" + id);

    dispatch({
      type: GET_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CATEGORYS_ERROR,
      payload: err,
    });
  }
};

export const addCategory = (data, formData, categories) => async (dispatch) => {
  try {
    console.log("Category", formData.category);
    console.log("SubCategory", formData.subcategory);
    const res = await upload(data);
    const images = res;
    const entry = {};
    if (images && images.length > 0) {
      entry.pic = images[0].url;
    }
    const { category, subcategory } = formData;
    if (category && subcategory) {
      console.log("We Are In");
      categories[category].subcategories[subcategory].subcategory.push({
        name: formData.name,
        slug: formData.slug,
        image: images && images.length > 0 ? images[0].url : null,
      });
    } else if (category && !subcategory) {
      console.log("We Are Here");
      categories[category].subcategories.push({
        name: formData.name,
        slug: formData.slug,
        image: images && images.length > 0 ? images[0].url : null,
        subcategory: [],
      });
    } else {
      console.log("We Are Atlast Here");
      categories.push({
        name: formData.name,
        slug: formData.slug,
        image: images && images.length > 0 ? images[0].url : null,
        subcategories: [],
      });
    }
    console.log(categories);
    const res1 = await api.put(`/category`, { main_categories: categories });

    dispatch({
      type: ADD_CATEGORY,
      payload: res1.data,
    });
    dispatch(setAlert("Saved Successfully", "success"));
  } catch (err) {
    dispatch({
      type: CATEGORYS_ERROR,
      payload: err,
    });
  }
};
export const editCategory = (data) => async (dispatch) => {
  try {
    const res = await api.put("/category", { main_categories: data });
  } catch (err) {
    dispatch({
      type: CATEGORYS_ERROR,
      payload: err,
    });
  }
};
