import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import slugify from "slugify";

import { addBrand, getBrands } from "../../actions/brands";
const AddBrand = ({
  addBrand,
  getBrands,
  alert,
  history,
  brand: { brands },
}) => {
  const [pic, setPic] = useState(null);
  const [slug, setSlug] = useState("");
  const [slugError, setSlugError] = useState(false);
  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    setSlugError(false);
    if (brands) {
      const filterData = brands.filter((item) => item.slug === slug);
      if (filterData && filterData.length > 0) {
        setSlugError(true);
      }
    }
  }, [slug, brands]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Brand</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb
                title="Add Brand"
                link_title="Brands"
                link_URI="/brands"
              />

              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Add Brand</h5>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          name: "",
                          slug: "",
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                          slug: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);

                          const formData = {
                            name: values.name,
                            slug: values.slug,
                          };

                          const newForm = new FormData();
                          newForm.append("data", JSON.stringify(formData));

                          if (pic) {
                            newForm.append("files", pic, pic.name);
                          }
                          await addBrand(newForm, formData, brands);
                          resetForm();
                          setPic(null);
                          history.push(`/brands`);
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Name </label>
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="Name"
                                    value={formik.values.name}
                                    onBlur={() => setSlug(formik.values.slug)}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "name",
                                        e.target.value
                                      );
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(
                                          e.target.value.toLocaleLowerCase()
                                        )
                                      );
                                    }}
                                  />
                                  {formik.errors.name &&
                                    formik.touched.name && (
                                      <p className="text-danger">
                                        {formik.errors.name}
                                      </p>
                                    )}
                                </div>
                                <div className="col-md-6">
                                  <label>Brand Image </label>
                                  <input
                                    type="file"
                                    name="singature"
                                    className="form-control-file"
                                    onChange={(event) =>
                                      setPic(event.target.files[0])
                                    }
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>Slug </label>
                                  <input
                                    type="text"
                                    name="slug"
                                    className="form-control"
                                    placeholder="Slug"
                                    value={formik.values.slug}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        "slug",
                                        slugify(
                                          e.target.value.toLocaleLowerCase()
                                        )
                                      )
                                    }
                                  />
                                  {slugError && (
                                    <p className="text-danger">
                                      already taken should unique
                                    </p>
                                  )}
                                </div>
                                {alert &&
                                  alert.map((item) => {
                                    return (
                                      <p className={`text-${item.alertType}`}>
                                        {item.msg}
                                      </p>
                                    );
                                  })}
                                <div className="col-md-12 mt-3">
                                  <button
                                    className="btn btn-primary"
                                    disabled={slugError}
                                  >
                                    {formik.isSubmitting ? "Saving..." : "Save"}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ alert: state.alert, brand: state.brand });

const mapDispatchToProps = { addBrand, getBrands };

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
