import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import brand from "./brands_reducer";
import category from "./category_reducer";
import coupon from "./coupons_reducer";
import product from "./product_reducer";
import customer from "./customer_reducer";
import order from "./orders_reducer";
import zipcode from "./zipcode_reducer";

export default combineReducers({
  auth,
  alert,
  brand,
  category,
  coupon,
  product,
  customer,
  order,
  zipcode,
});
