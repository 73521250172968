import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import { getProduct, editProduct } from "../../actions/products";
import SingleElement from "../../components/SingelItem";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
import Spinner from "../../layout/Spinner";
const SingleBrand = ({
  match,
  getProduct,
  editProduct,
  product: { product },
}) => {
  useEffect(() => {
    getProduct(match.params.id);
  }, [match.params]);
  const [deleting, setDeleting] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const deleteVariation = async (index) => {
    setDeleting(true);
    setDeletingIndex(index);
    const newProduct = product;
    let variations = newProduct.variations;
    const filteredData = variations.filter((item, i) => i !== index);
    newProduct.variations = filteredData;
    if (newProduct.variations.length === 0) {
      newProduct.is_variable_product = false;
    }
    await editProduct(product._id, null, newProduct);
    setDeleting(false);
    setDeletingIndex(null);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>View Product</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      {product && (
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <Breadcrumb
                  title="View Product"
                  link_title="Products"
                  link_URI="/products"
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">View Product</h5>
                        <Link to={`/products/${product._id}/edit`}>
                          <i className="fa fa-edit"></i> Edit
                        </Link>

                        <Link
                          to={`/products/${product._id}/add-variation`}
                          style={{ marginLeft: "20px" }}
                        >
                          <i className="fa fa-plus"></i> Add Variation
                        </Link>
                      </div>
                      <div className="card-body">
                        <SingleElement title="Name" value={product.name} />
                        <SingleElement
                          title="Category"
                          value={product.category_slug}
                        />
                        <SingleElement title="Brand" value={product.brand} />
                        <SingleElement title="Slug" value={product.slug} />
                        <SingleElement
                          title="Sale Price"
                          value={product.sale_price}
                        />
                        <SingleElement
                          title="Regular Price"
                          value={product.regular_price}
                        />
                        <SingleElement title="Stock" value={product.stock} />

                        <SingleElement title="Pics">
                          {product.pics &&
                            product.pics.map((img) => {
                              return (
                                <img
                                  src={`${img}`}
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              );
                            })}
                        </SingleElement>
                        <SingleElement
                          title="Description"
                          value={product.description}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-12"
                    style={{ background: "#fff", padding: "20px 20px" }}
                  >
                    <h3> Variations </h3>
                    {product &&
                      !product.is_variable_product &&
                      "No Variation Available"}
                    {product && product.is_variable_product && (
                      <div className="table-responsive">
                        <table className=" table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Regular Price</th>
                              <th>Sale Price</th>
                              <th>Stock</th>
                              <th>Variation Label</th>
                              <th>Variation Value</th>
                              <th>Pics</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {product &&
                              product.variations &&
                              product.variations.map((item, index) => {
                                return (
                                  <tr>
                                    <td> {index + 1} </td>
                                    <td> {item.regular_price} </td>
                                    <td>{item.sale_price}</td>
                                    <td>{item.stock}</td>
                                    <td>{item.variation_label}</td>
                                    <td>{item.variation_value}</td>
                                    <td>
                                      {" "}
                                      {item.pics &&
                                        item.pics.map((img) => {
                                          return (
                                            <img
                                              src={`${img}`}
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                              }}
                                            />
                                          );
                                        })}{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <button
                                        className="btn btn-danger"
                                        onClick={() => deleteVariation(index)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          position: "fixed",
          top: "10%",
          width: "100%",
          display: deleting ? "block" : "none",
        }}
      >
        <div>
          <Spinner />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ product: state.product });

const mapDispatchToProps = { getProduct, editProduct };

export default connect(mapStateToProps, mapDispatchToProps)(SingleBrand);
