import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import { getCoupon } from "../../actions/coupons";
import SingleElement from "../../components/SingelItem";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const SingleCoupon = ({ match, getCoupon, coupon: { coupon } }) => {
  useEffect(() => {
    getCoupon(match.params.id);
  }, [match.params]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>View Category</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      {coupon && (
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <Breadcrumb
                  title="View Coupon"
                  link_title="Coupons"
                  link_URI="/coupons"
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">View Coupon</h5>
                        <Link to={`/coupons/${coupon._id}/edit`}>
                          <i className="fa fa-edit"></i> Edit
                        </Link>
                      </div>
                      <div className="card-body">
                        <SingleElement title="Code" value={coupon.code} />
                        <SingleElement
                          title="Discount Type"
                          value={coupon.discount_type}
                        />

                        <SingleElement
                          title="Discount"
                          value={coupon.discount}
                        />
                        <SingleElement
                          title="Max Discount"
                          value={coupon.max_discount}
                        />
                        <SingleElement
                          title="Min Purchase Amount"
                          value={coupon.min_purchase_amount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ coupon: state.coupon });

const mapDispatchToProps = { getCoupon };

export default connect(mapStateToProps, mapDispatchToProps)(SingleCoupon);
