import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";

import { getBrands, editBrand } from "../../actions/brands";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const AddBrand = ({ getBrands, editBrand, brand: { brands } }) => {
  useEffect(() => {
    getBrands();
  }, []);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const confirmDelete = (id) => {
    setIdToDelete(id);
    setShowConfirmPopup(true);
  };

  const deleteEntry = async () => {
    setDeleting(true);
    console.log("ID to delete", idToDelete);
    const newBrand = brands;
    if (newBrand) {
      const filteredData = newBrand.filter(
        (item, index) => index !== idToDelete
      );
      await editBrand(filteredData);
      await getBrands();
      setIdToDelete(null);
      setShowConfirmPopup(false);
      setDeleting(false);
    }
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Brands</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb title="Brands" />

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Brands</h5>

                      <Link className="btn btn-sm btn-success" to="/brands/add">
                        Add New
                      </Link>
                    </div>
                    <div className="card-body">
                      <div className="table-card">
                        <div className="">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>
                                    <span>#</span>
                                  </th>
                                  <th>
                                    <span>Name</span>
                                  </th>
                                  <th>
                                    <span>Pic</span>
                                  </th>
                                  <th>
                                    <span>Action</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {brands &&
                                  brands.map((item, index) => {
                                    return (
                                      <tr key={item._id}>
                                        <td> {index + 1} </td>
                                        <td>{item.name}</td>
                                        <td>
                                          {item.pic && (
                                            <img
                                              src={`${URI}${item.pic}`}
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                              }}
                                            />
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            onClick={() => confirmDelete(index)}
                                            className="btn btn-sm btn-danger"
                                          >
                                            <i className="fa fa-trash"></i>{" "}
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmPopup && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            zIndex: 999,
            background: "rgba(0,0,0,0.5)",
            height: "100vh",
            top: 0,
          }}
        >
          <div
            style={{
              background: "#fff",
              maxWidth: "400px",
              margin: "80px auto",
              borderRadius: "10px",
            }}
          >
            <div style={{ padding: "20px 20px" }} className="text-center">
              <h3>Do You Want to delete?</h3>
              <div className="m-3">
                <div className="d-flex justify-content-evenly ">
                  <button
                    className="btn btn-secondary btn-block"
                    onClick={() => setShowConfirmPopup(false)}
                  >
                    {" "}
                    No{" "}
                  </button>
                  <button
                    className="btn btn-danger btn-block"
                    onClick={() => deleteEntry()}
                  >
                    {deleting ? "Deleting..." : "Delete"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ brand: state.brand });

const mapDispatchToProps = { getBrands, editBrand };

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
