import {
  GET_COUPONS,
  TOTAL_COUPONS,
  ADD_COUPON,
  EDIT_COUPON,
  GET_COUPON,
  COUPONS_ERROR,
} from "../actions/types";

const initialState = {
  coupons: null,
  coupon: null,
  total_coupons: 0,
  loading: true,
  error: {},
  coupon_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COUPONS:
      return {
        ...state,
        coupons: payload,
        loading: false,
        error: {},
      };

    case TOTAL_COUPONS:
      return {
        ...state,
        total_coupons: payload,
        loading: false,
        error: {},
      };

    case ADD_COUPON:
      return {
        ...state,
        coupon_message: payload,
        loading: false,
        error: {},
      };
    case GET_COUPON:
      return {
        ...state,
        coupon: payload,
        loading: false,
        error: {},
      };
    case EDIT_COUPON:
      return {
        ...state,
        coupon_message: payload,
        loading: false,
        error: {},
      };

    case COUPONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
