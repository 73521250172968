import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb({ title, link_title, link_URI }) {
  return (
    <div className="page-header">
      <div className="page-block">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="page-header-title">
              <h5 className="m-b-10">{title}</h5>
            </div>
            <ul className="breadcrumb  ">
              <li className="breadcrumb-item  ">
                <Link to="/">
                  <i className="fa fa-home" />
                </Link>
              </li>
              {link_URI && (
                <li className="breadcrumb-item  ">
                  <Link to={link_URI}>{link_title}</Link>
                </li>
              )}

              <li className="breadcrumb-item  ">{title}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;
