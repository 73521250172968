import api from "../utils/api";
import { setAlert } from "./alert";
import {
  GET_ZIPCODES,
  TOTAL_ZIPCODES,
  ADD_ZIPCODE,
  GET_ZIPCODE,
  ZIPCODES_ERROR,
} from "./types";
import { upload } from "./functions";
// Get posts
export const getZipcodes = (page, searchParams) => async (dispatch) => {
  try {
    let start = 0;
    let limit = 10;
    if (page) {
      start = (page - 1) * 10;
    }
    let query =
      "/brands?_limit=" + limit + "&_start=" + start + "&_sort=createdAt:DESC";
    if (searchParams) {
      let newQ = ``;
      searchParams.map((item) => {
        newQ += `&${item.label}=${item.value}`;
      });
      console.log("Query", newQ);
      query = query + newQ;
    }

    const res = await api.get("/zipcode");
    if (res.data) {
      dispatch({
        type: GET_ZIPCODES,
        payload: res.data.zipcodes,
      });
    }
  } catch (err) {
    dispatch({
      type: ZIPCODES_ERROR,
      payload: err,
    });
  }
};

export const addZipcode = (data) => async (dispatch) => {
  try {
    const res1 = await api.put(`/zipcode`, { zipcodes: data });

    dispatch({
      type: ADD_ZIPCODE,
      payload: res1.data,
    });
    dispatch(setAlert("Saved Successfully", "success"));
  } catch (err) {
    dispatch(setAlert("Something Went wrong", "danger"));
  }
};
export const editZipcode = (data) => async (dispatch) => {
  try {
    const res = await api.put("/zipcode", { zipcodes: data });
  } catch (err) {
    dispatch({
      type: ZIPCODES_ERROR,
      payload: err,
    });
  }
};
