import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";

import { countProduct, getProducts } from "../../actions/products";
import { getCategorys } from "../../actions/categories";
import { getBrands } from "../../actions/brands";
import Pagination from "../../components/Pagination";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const AddBrand = ({
  getProducts,
  getBrands,
  countProduct,
  getCategorys,
  product: { products, total_products },
  brand: { brands },
}) => {
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState([]);
  useEffect(() => {
    getCategorys();
    getBrands();
  }, []);

  const addSearchParams = ({ label, value, title }) => {
    const filteredData = searchParams.filter((item, index) => {
      return item.label === label;
    });
    if (filteredData.length > 0) {
      const newArray = searchParams.map((item) => {
        if (item.label === label) {
          item.label = label;
          item.value = value;
          item.title = title;
        }
        return item;
      });
      setSearchParams(newArray);
    } else {
      setSearchParams([...searchParams, { label, value, title }]);
    }
  };
  const removeSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label !== label);
    setSearchParams(filteredData);
  };

  const getSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label === label);
    if (filteredData.length > 0) {
      return filteredData[0].value;
    } else {
      return "";
    }
  };
  const changePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    getProducts(page, searchParams);
  }, [page, searchParams]);
  useEffect(() => {
    countProduct(searchParams);
  }, [searchParams]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb title="Products" />

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Products</h5>

                      <Link
                        className="btn btn-sm btn-success"
                        to="/products/add"
                      >
                        Add New
                      </Link>
                    </div>
                    <div className="card-body">
                      <div style={{ display: "flex" }}>
                        {searchParams &&
                          searchParams.length > 0 &&
                          searchParams.map((item) => {
                            return (
                              <div
                                style={{
                                  background: "#fff",
                                  padding: "10px 10px",
                                  marginRight: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                {item.title} : {item.value}{" "}
                                <button
                                  onClick={() => removeSearchParams(item.label)}
                                  className="btn btn-sm btn-danger"
                                >
                                  X
                                </button>
                              </div>
                            );
                          })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingBottom: "20px",
                        }}
                      >
                        <div style={{ flex: 2 }}>
                          <h4> Filters </h4>
                        </div>
                        <div style={{ flex: 2 }}>
                          <div>
                            <label> Search </label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              addSearchParams({
                                label: "_q",
                                value: e.target.value,
                                title: "Query",
                              });
                            }}
                            value={getSearchParams("_q")}
                          ></input>
                        </div>
                        <div
                          style={{
                            flex: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div>
                            <div>
                              <label> From Date </label>
                            </div>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) => {
                                addSearchParams({
                                  label: "createdAt_gte",
                                  value: e.target.value,
                                  title: "From Date",
                                });
                              }}
                              value={getSearchParams("createdAt_gte")}
                            ></input>
                          </div>

                          <div>
                            <div>
                              <label> To Date </label>
                            </div>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) => {
                                addSearchParams({
                                  label: "createdAt_lte",
                                  value: e.target.value,
                                  title: "To Date",
                                });
                              }}
                              value={getSearchParams("createdAt_lte")}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="table-card">
                        <div className="">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>
                                    <span>#</span>
                                  </th>
                                  <th>
                                    <span>Name</span>
                                  </th>
                                  <th>
                                    <span>Price</span>
                                  </th>
                                  <th>
                                    <span>Pic</span>
                                  </th>

                                  <th>
                                    <span>Category</span>
                                  </th>
                                  <th>
                                    <span> Sub Category</span>
                                  </th>
                                  <th>
                                    <span> Main Category</span>
                                  </th>
                                  <th>
                                    <span>Brand</span>
                                  </th>
                                  <th>
                                    <span>Action</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {products &&
                                  products.map((item, index) => {
                                    return (
                                      <tr key={item._id}>
                                        <td>
                                          {" "}
                                          {(page - 1) * 100 + index + 1}{" "}
                                        </td>
                                        <td>{item.name}</td>
                                        <td>
                                          ₹{" "}
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {item.regular_price}
                                          </span>
                                          <h6>₹{item.sale_price}</h6>
                                        </td>
                                        <td>
                                          {item.pics &&
                                            item.pics.map((img) => {
                                              return (
                                                <img
                                                  src={`${URI}${img}`}
                                                  style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    objectFit: "cover",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              );
                                            })}
                                        </td>
                                        <td>
                                          {item.category_slug &&
                                            item.category_slug}
                                        </td>
                                        <td>
                                          {item.subcategory_slug &&
                                            item.subcategory_slug}
                                        </td>
                                        <td>
                                          {item.main_category_slug &&
                                            item.main_category_slug}
                                        </td>

                                        <td> {item.brand && item.brand}</td>

                                        <td>
                                          <Link
                                            to={`/products/${item._id}/view`}
                                            className="btn btn-sm btn-primary"
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                          <Link
                                            to={`/products/${item._id}/edit`}
                                            className="btn btn-sm btn-warning m-1"
                                          >
                                            <i className="fa fa-edit"></i>
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="text-left">
                            <h3> Total Result: {total_products} </h3>
                          </div>
                          {total_products && (
                            <Pagination
                              total={total_products}
                              current_page={page}
                              limit={100}
                              changePage={(page) => changePage(page)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
  category: state.category,
  brand: state.brand,
});

const mapDispatchToProps = {
  getProducts,
  countProduct,
  getCategorys,
  getBrands,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
