import {
  GET_CATEGORYS,
  TOTAL_CATEGORYS,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY,
  CATEGORYS_ERROR,
} from "../actions/types";

const initialState = {
  categories: null,
  category: null,
  total_categories: 0,
  loading: true,
  error: {},
  category_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORYS:
      return {
        ...state,
        categories: payload,
        loading: false,
        error: {},
      };

    case TOTAL_CATEGORYS:
      return {
        ...state,
        total_categories: payload,
        loading: false,
        error: {},
      };

    case ADD_CATEGORY:
      return {
        ...state,
        category_message: payload,
        loading: false,
        error: {},
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: payload,
        loading: false,
        error: {},
      };
    case EDIT_CATEGORY:
      return {
        ...state,
        category_message: payload,
        loading: false,
        error: {},
      };

    case CATEGORYS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
