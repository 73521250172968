import React from "react";

function SingleElement({ title, value, children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #f1f1f1",
        padding: "10px 0px",
      }}
    >
      <div style={{ flex: 1, color: "#315a73" }}>{title}</div>
      <div style={{ flex: 2 }}>
        {value} {children}
      </div>
    </div>
  );
}

export default SingleElement;
