import {
  GET_CUSTOMERS,
  TOTAL_CUSTOMERS,
  ADD_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMER,
  CUSTOMERS_ERROR,
} from "../actions/types";

const initialState = {
  customers: null,
  customer: null,
  total_customers: 0,
  loading: true,
  error: {},
  customer_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: payload,
        loading: false,
        error: {},
      };

    case TOTAL_CUSTOMERS:
      return {
        ...state,
        total_customers: payload,
        loading: false,
        error: {},
      };

    case ADD_CUSTOMER:
      return {
        ...state,
        customer_message: payload,
        loading: false,
        error: {},
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customer: payload,
        loading: false,
        error: {},
      };
    case EDIT_CUSTOMER:
      return {
        ...state,
        customer_message: payload,
        loading: false,
        error: {},
      };

    case CUSTOMERS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
