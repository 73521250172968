import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";

import { countOrder, getOrders } from "../../actions/orders";
import Pagination from "../../components/Pagination";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const AddBrand = ({
  getOrders,
  countOrder,
  order: { orders, total_orders },
}) => {
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState([]);
  const addSearchParams = ({ label, value, title }) => {
    const filteredData = searchParams.filter((item, index) => {
      return item.label === label;
    });
    if (filteredData.length > 0) {
      const newArray = searchParams.map((item) => {
        if (item.label === label) {
          item.label = label;
          item.value = value;
          item.title = title;
        }
        return item;
      });
      setSearchParams(newArray);
    } else {
      setSearchParams([...searchParams, { label, value, title }]);
    }
  };
  const removeSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label !== label);
    setSearchParams(filteredData);
  };

  const getSearchParams = (label) => {
    const filteredData = searchParams.filter((item) => item.label === label);
    if (filteredData.length > 0) {
      return filteredData[0].value;
    } else {
      return "";
    }
  };
  const changePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    getOrders(page, searchParams);
  }, [page, searchParams]);
  useEffect(() => {
    countOrder(searchParams);
  }, [searchParams]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Brand</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb title="Orders" />

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Orders</h5>

                      <Link className="btn btn-sm btn-success" to="/orders/add">
                        Add New
                      </Link>
                    </div>
                    <div className="card-body">
                      <div style={{ display: "flex" }}>
                        {searchParams &&
                          searchParams.length > 0 &&
                          searchParams.map((item) => {
                            return (
                              <div
                                style={{
                                  background: "#fff",
                                  padding: "10px 10px",
                                  marginRight: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                {item.title} : {item.value}{" "}
                                <button
                                  onClick={() => removeSearchParams(item.label)}
                                  className="btn btn-sm btn-danger"
                                >
                                  X
                                </button>
                              </div>
                            );
                          })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingBottom: "20px",
                        }}
                      >
                        <div style={{ flex: 2 }}>
                          <h4> Filters </h4>
                        </div>
                        <div style={{ flex: 2 }}>
                          <div>
                            <label> Search </label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              addSearchParams({
                                label: "_q",
                                value: e.target.value,
                                title: "Query",
                              });
                            }}
                            value={getSearchParams("_q")}
                          ></input>
                        </div>
                        <div
                          style={{
                            flex: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div>
                            <div>
                              <label> From Date </label>
                            </div>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) => {
                                addSearchParams({
                                  label: "createdAt_gte",
                                  value: e.target.value,
                                  title: "From Date",
                                });
                              }}
                              value={getSearchParams("createdAt_gte")}
                            ></input>
                          </div>

                          <div>
                            <div>
                              <label> To Date </label>
                            </div>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) => {
                                addSearchParams({
                                  label: "createdAt_lte",
                                  value: e.target.value,
                                  title: "To Date",
                                });
                              }}
                              value={getSearchParams("createdAt_lte")}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="table-card">
                        <div className="">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>
                                    <span>#</span>
                                  </th>
                                  <th>
                                    <span>Amount Total</span>
                                  </th>
                                  <th>
                                    <span>Customer</span>
                                  </th>
                                  <th>
                                    <span>Pin</span>
                                  </th>
                                  <th>
                                    <span>Status</span>
                                  </th>
                                  <th>
                                    <span>Action</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders &&
                                  orders.map((item, index) => {
                                    return (
                                      <tr key={item._id}>
                                        <td> {index + 1} </td>
                                        <td>{item.total_amount}</td>
                                        <td>
                                          {item.customer &&
                                            item.customer.first_name}{" "}
                                          {item.customer &&
                                            item.customer.last_name}
                                        </td>
                                        <td>
                                          {item.address && item.address.zipcode}
                                        </td>
                                        <td> {item.order_status} </td>

                                        <td>
                                          <Link
                                            to={`/orders/${item._id}/view`}
                                            className="btn btn-sm btn-primary"
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                          <Link
                                            to={`/orders/${item._id}/edit`}
                                            className="btn btn-sm btn-secondary m-1"
                                          >
                                            <i className="fa fa-edit"></i>
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="text-left">
                          <h3> Total Result: {total_orders} </h3>
                        </div>
                        {total_orders && (
                          <Pagination
                            total={total_orders}
                            current_page={page}
                            limit={10}
                            changePage={(page) => changePage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ order: state.order });

const mapDispatchToProps = { getOrders, countOrder };

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
