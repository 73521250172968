import {
  GET_BRANDS,
  TOTAL_BRANDS,
  ADD_BRAND,
  EDIT_BRAND,
  GET_BRAND,
  BRANDS_ERROR,
} from "../actions/types";

const initialState = {
  brands: null,
  brand: null,
  total_brands: 0,
  loading: true,
  error: {},
  brand_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BRANDS:
      return {
        ...state,
        brands: payload,
        loading: false,
        error: {},
      };

    case TOTAL_BRANDS:
      return {
        ...state,
        total_brands: payload,
        loading: false,
        error: {},
      };

    case ADD_BRAND:
      return {
        ...state,
        brand_message: payload,
        loading: false,
        error: {},
      };
    case GET_BRAND:
      return {
        ...state,
        brand: payload,
        loading: false,
        error: {},
      };
    case EDIT_BRAND:
      return {
        ...state,
        brand_message: payload,
        loading: false,
        error: {},
      };

    case BRANDS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
