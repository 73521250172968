import React from "react";
import { connect } from "react-redux";
import { logout } from "../actions/auth";

const Navbar = ({ logout }) => {
  return (
    <div className="navbar pcoded-header navbar-expand-lg header-blue headerpos-fixed">
      <ul className="navbar-nav mr-auto  ">
        <div className="m-header">
          <a
            className="mobile-menu"
            id="mobile-collapse"
            href="/demos/admin-templates/gradient-able/react/default/app/dashboard/analytics"
          >
            <span />
          </a>
          <a
            className="main-logo"
            href="/demos/admin-templates/gradient-able/react/default/app/dashboard/analytics"
          >
            Admin Panel
          </a>
        </div>
      </ul>
      <ul className="navbar-nav mr-auto">
        <div className="login-icon">
          <i className="fa fa-user"></i>
        </div>
        <div className="login-name position-relative"> Admin Panel </div>
        <div>
          <button className="btn btn-danger mt-2" onClick={() => logout()}>
            Logout
          </button>
        </div>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
