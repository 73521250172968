import React, { useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput, SelectBox } from "../../components/Form/Form";
import { addCoupon } from "../../actions/coupons";
const AddCoupon = ({ addCoupon, alert, history }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Coupon</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb
                title="Add Coupon"
                link_title="Coupon"
                link_URI="/coupons"
              />

              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Add Coupon</h5>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          code: "",
                          discount_type: "",
                          discount: "",
                          min_purchase_amount: "",
                          max_discount: "",
                        }}
                        validationSchema={Yup.object({
                          code: Yup.string().required("Required"),
                          discount_type: Yup.string().required("Required"),
                          discount: Yup.string().required("Required"),
                          min_purchase_amount: Yup.string().required(
                            "Required"
                          ),
                          max_discount: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);

                          const newForm = new FormData();
                          newForm.append("data", JSON.stringify(values));

                          await addCoupon(newForm);
                          resetForm();

                          history.push(`/coupons`);
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="Code"
                                    name="code"
                                    type="text"
                                    placeholder="Enter Code"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <SelectBox
                                    label="Discount Type"
                                    name="discount_type"
                                    placeholder="Enter Discount Type"
                                  >
                                    <option value="">
                                      Select Discount Type
                                    </option>
                                    <option value="FIXED"> FIXED </option>
                                    <option value="PERCENTAGE">
                                      PERCENTAGE
                                    </option>
                                  </SelectBox>
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Discount"
                                    name="discount"
                                    type="number"
                                    placeholder="Enter Discount"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Min Purchase Amount"
                                    name="min_purchase_amount"
                                    type="number"
                                    placeholder="Enter Min Purchase Amount"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Max Discount"
                                    name="max_discount"
                                    type=" number"
                                    placeholder="Enter Max Discount"
                                  />
                                </div>

                                {alert &&
                                  alert.map((item) => {
                                    return (
                                      <p className={`text-${item.alertType}`}>
                                        {item.msg}
                                      </p>
                                    );
                                  })}
                                <div className="col-md-12 mt-3">
                                  <button className="btn btn-primary">
                                    {formik.isSubmitting ? "Saving..." : "Save"}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { addCoupon };

export default connect(mapStateToProps, mapDispatchToProps)(AddCoupon);
