import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import { getCategory } from "../../actions/categories";
import SingleElement from "../../components/SingelItem";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const SingleBrand = ({ match, getCategory, category: { category } }) => {
  useEffect(() => {
    getCategory(match.params.id);
  }, [match.params]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>View Category</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      {category && (
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <Breadcrumb
                  title="View Category"
                  link_title="Categories"
                  link_URI="/categories"
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">View Category</h5>
                        <Link to={`/categories/${category._id}/edit`}>
                          <i className="fa fa-edit"></i> Edit
                        </Link>
                      </div>
                      <div className="card-body">
                        <SingleElement title="Name" value={category.name} />
                        <SingleElement title="Pic">
                          <img
                            src={`${URI}${category.pic.url}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                          />
                        </SingleElement>
                        <SingleElement title="Slug" value={category.slug} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ category: state.category });

const mapDispatchToProps = { getCategory };

export default connect(mapStateToProps, mapDispatchToProps)(SingleBrand);
