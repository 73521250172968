import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";

import { getCategorys, editCategory } from "../../actions/categories";

import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const AddBrand = ({ getCategorys, editCategory, category: { categories } }) => {
  useEffect(() => {
    getCategorys();
  }, []);
  const deleteCategory = (i) => {
    console.log("Delete Category", i);
    confirmDelete({ main: i });
  };
  const deleteSubCat = (i, j) => {
    console.log("Delete Sub Cat", i, j);
    confirmDelete({ main: i, sub_cat: j });
  };
  const deleteSubSubCat = (i, j, k) => {
    console.log("Delete Sub Sub Cat", i, j, k);
    confirmDelete({ main: i, sub_cat: j, cat: k });
  };
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const confirmDelete = (id) => {
    setIdToDelete(id);
    setShowConfirmPopup(true);
  };
  const deleteEntry = async () => {
    setDeleting(true);
    console.log("ID to delete", idToDelete);
    const newCat = categories;
    if (newCat) {
      let filteredData = [];

      if (
        idToDelete &&
        typeof idToDelete.cat !== "undefined" &&
        typeof idToDelete.sub_cat !== "undefined" &&
        typeof idToDelete.main !== "undefined"
      ) {
        const newData =
          newCat[idToDelete.main].subcategories[idToDelete.sub_cat].subcategory;
        filteredData = newData.filter(
          (item, index) => index !== idToDelete.cat
        );
        newCat[idToDelete.main].subcategories[idToDelete.sub_cat].subcategory =
          filteredData;
        // await editCategory(newCat);
      }
      if (
        idToDelete &&
        typeof idToDelete.sub_cat !== "undefined" &&
        typeof idToDelete.main !== "undefined" &&
        typeof idToDelete.cat === "undefined"
      ) {
        console.log("Inside This");
        const newData = newCat[idToDelete.main].subcategories;
        filteredData = newData.filter(
          (item, index) => index !== idToDelete.sub_cat
        );
        newCat[idToDelete.main].subcategories = filteredData;
        // await editCategory(newCat);
      }
      if (
        idToDelete &&
        typeof idToDelete.sub_cat === "undefined" &&
        typeof idToDelete.main !== "undefined" &&
        typeof idToDelete.cat === "undefined"
      ) {
        console.log("Inside This Dun");
        const newData = newCat;
        filteredData = newCat.filter(
          (item, index) => index !== idToDelete.main
        );

        // await editCategory(filteredData);
      }

      // await editBrand(filteredData);
      await getCategorys();
      setIdToDelete(null);
      setShowConfirmPopup(false);
      setDeleting(false);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Categories</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb title="Categories" />

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Categories</h5>

                      <Link
                        className="btn btn-sm btn-success"
                        to="/categories/add"
                      >
                        Add New
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {categories &&
                  categories.map((item, i) => {
                    return (
                      <div className="col-md-3">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              background: "#f1f1f1",
                              padding: "10px 10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h5 className="card-title">{item.name}</h5>
                            </div>
                            <div>
                              <button
                                className="btn-danger"
                                onClick={() => deleteCategory(i)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                          <div
                            className="card-body"
                            style={{ height: "500px", overflowY: "scroll" }}
                          >
                            <ul>
                              {item.subcategories &&
                                item.subcategories.map((cat, j) => {
                                  return (
                                    <li>
                                      <div
                                        className="d-flex justify-content-between p-3"
                                        style={{
                                          background: "rgb(234, 255, 253)",
                                        }}
                                      >
                                        <div>{cat.name}</div>

                                        <div>
                                          <button
                                            onClick={() => deleteSubCat(i, j)}
                                            className="btn-danger"
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>
                                      </div>

                                      {cat.subcategory &&
                                        cat.subcategory.map((subcat, k) => {
                                          return (
                                            <ul>
                                              <li>
                                                <div className="d-flex justify-content-between p-2">
                                                  <div>{subcat.name}</div>
                                                  <div>
                                                    <button
                                                      onClick={() =>
                                                        deleteSubSubCat(i, j, k)
                                                      }
                                                      className="btn-danger"
                                                    >
                                                      <i className="fa fa-trash"></i>
                                                    </button>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          );
                                        })}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmPopup && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            zIndex: 999,
            background: "rgba(0,0,0,0.5)",
            height: "100vh",
            top: 0,
          }}
        >
          <div
            style={{
              background: "#fff",
              maxWidth: "400px",
              margin: "80px auto",
              borderRadius: "10px",
            }}
          >
            <div style={{ padding: "20px 20px" }} className="text-center">
              <h3>Do You Want to delete?</h3>
              <div className="m-3">
                <div className="d-flex justify-content-evenly ">
                  <button
                    className="btn btn-secondary btn-block"
                    onClick={() => setShowConfirmPopup(false)}
                  >
                    {" "}
                    No{" "}
                  </button>
                  <button
                    className="btn btn-danger btn-block"
                    onClick={() => deleteEntry()}
                  >
                    {deleting ? "Deleting..." : "Delete"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ category: state.category });

const mapDispatchToProps = { getCategorys, editCategory };

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
