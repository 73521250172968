import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import { getCustomer } from "../../actions/cutomers";
import SingleElement from "../../components/SingelItem";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
const SingleBrand = ({ match, getCustomer, customer: { customer } }) => {
  useEffect(() => {
    getCustomer(match.params.id);
  }, [match.params]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>View Customer</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      {customer && (
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <Breadcrumb
                  title="View customer"
                  link_title="Customers"
                  link_URI="/customers"
                />

                <div className="row">
                  <div className="col-md-9">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">View Customer</h5>
                      </div>
                      <div className="card-body">
                        <SingleElement title="Name" value={customer.name} />

                        <SingleElement title="Email" value={customer.email} />
                        <SingleElement title="Phone" value={customer.phone} />
                        <SingleElement
                          title="Username"
                          value={customer.username}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <strong> Address </strong>
                    </p>
                  </div>
                  {customer &&
                    customer.address &&
                    customer.address.map((item) => {
                      return (
                        <div className="col-md-4">
                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title">{item.label}</h5>
                            </div>
                            <div className="card-body">
                              <SingleElement title="Name" value={item.name} />

                              <SingleElement
                                title="Address"
                                value={`${item.address_1} ${item.address_2}`}
                              />
                              <SingleElement
                                title="Landmark"
                                value={item.landmark}
                              />
                              <SingleElement title="City" value={item.city} />
                              <SingleElement title="State" value={item.state} />
                              <SingleElement title="Pin" value={item.pin} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ customer: state.customer });

const mapDispatchToProps = { getCustomer };

export default connect(mapStateToProps, mapDispatchToProps)(SingleBrand);
