import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import { Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { TextInput, SelectBox } from "../../components/Form/Form";
import { addProduct } from "../../actions/products";
import { getCategorys } from "../../actions/categories";
import { getBrands } from "../../actions/brands";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import { createVariation } from "../../actions/functions";

const AddProduct = ({
  addProduct,
  alert,
  history,
  getBrands,
  getCategorys,
  category: { categories },
  brand: { brands },
}) => {
  const [pic, setPic] = useState(null);
  useEffect(() => {
    getCategorys();
  }, []);
  useEffect(() => {
    getBrands();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Product</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb
                title="Add Product"
                link_title="Product"
                link_URI="/products"
              />

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Add Product</h5>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          name: "",
                          category: "",
                          brand: "",
                          regular_price: 0,
                          sale_price: 0,
                          quantity: "",
                          stock: 500,

                          product_details: [{ label: "", value: "" }],
                        }}
                        validationSchema={Yup.object({
                          // name: Yup.string().required("Required"),
                          // category: Yup.string().required("Required"),
                          // brand: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          console.log("Submitted");
                          console.log(values);

                          const newForm = new FormData();

                          newForm.append("data", JSON.stringify(values));

                          if (values.pic) {
                            Array.from(values.pic).forEach((image, index) => {
                              newForm.append(`files`, image, image.name);
                            });
                          }
                          console.log(newForm);

                          await addProduct(newForm, values);
                          resetForm();
                          setPic(null);
                          history.push(`/products`);
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <div className="row"></div>

                              <div className="row">
                                <div className="col-md-12">
                                  <FieldArray name="product">
                                    {(fieldArrayProps) => {
                                      const { push, remove, form } =
                                        fieldArrayProps;
                                      const { values } = form;
                                      const { product } = values;
                                      // console.log('fieldArrayProps', fieldArrayProps)
                                      // console.log('Form errors', form.errors)
                                      return (
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div
                                              className="row"
                                              style={{
                                                border: "2px solid #f1f1f1",
                                                padding: "10px 10px",
                                                margin: "10px 5px",
                                                background: "#eafffd",
                                              }}
                                            >
                                              <div className="col-md-12">
                                                <div className="d-flex justify-content-between">
                                                  <div>
                                                    <p>
                                                      <strong>Product</strong>
                                                    </p>
                                                  </div>
                                                  <div></div>
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <TextInput
                                                  label="Name"
                                                  name={`name`}
                                                  type="text"
                                                  placeholder="Enter Name"
                                                />
                                              </div>
                                              <div className="col-md-6">
                                                <SelectBox
                                                  label="Store Category"
                                                  name={`category_slug`}
                                                  placeholder="Enter Category"
                                                  onChange={(e) => {
                                                    formik.setFieldValue(
                                                      `category_slug`,
                                                      e.target.value
                                                    );
                                                    formik.setFieldValue(
                                                      `subcategory_slug`,
                                                      ""
                                                    );
                                                    formik.setFieldValue(
                                                      `main_category_slug`,
                                                      ""
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Category
                                                  </option>
                                                  {categories &&
                                                    categories.map(
                                                      (item, index) => {
                                                        return (
                                                          <option
                                                            value={item.slug}
                                                          >
                                                            {item.name}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                </SelectBox>
                                              </div>
                                              <div className="col-md-6">
                                                <SelectBox
                                                  label="Sub Category"
                                                  name={`subcategory_slug`}
                                                  placeholder="Enter Category"
                                                  onChange={(e) => {
                                                    formik.setFieldValue(
                                                      `subcategory_slug`,
                                                      e.target.value
                                                    );

                                                    formik.setFieldValue(
                                                      `main_category_slug`,
                                                      ""
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Category
                                                  </option>

                                                  {categories &&
                                                    categories.filter(
                                                      (item) =>
                                                        item.slug ===
                                                        formik.values
                                                          .category_slug
                                                    )[0] &&
                                                    categories.filter(
                                                      (item) =>
                                                        item.slug ===
                                                        formik.values
                                                          .category_slug
                                                    )[0].subcategories &&
                                                    categories
                                                      .filter(
                                                        (item) =>
                                                          item.slug ===
                                                          formik.values
                                                            .category_slug
                                                      )[0]
                                                      .subcategories.map(
                                                        (item, index) => {
                                                          return (
                                                            <option
                                                              value={item.slug}
                                                            >
                                                              {item.name}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                </SelectBox>
                                              </div>
                                              <div className="col-md-6">
                                                <SelectBox
                                                  label="Main Category"
                                                  name={`main_category_slug`}
                                                  placeholder="Enter Category"
                                                >
                                                  <option value="">
                                                    Select Category
                                                  </option>

                                                  {categories &&
                                                    categories.filter(
                                                      (c) =>
                                                        c.slug ===
                                                        formik.values
                                                          .category_slug
                                                    )[0] &&
                                                    categories.filter(
                                                      (item) =>
                                                        item.slug ===
                                                        formik.values
                                                          .category_slug
                                                    )[0].subcategories &&
                                                    categories
                                                      .filter(
                                                        (item) =>
                                                          item.slug ===
                                                          formik.values
                                                            .category_slug
                                                      )[0]
                                                      .subcategories.filter(
                                                        (cat1) =>
                                                          cat1.slug ===
                                                          formik.values
                                                            .subcategory_slug
                                                      )[0] &&
                                                    categories
                                                      .filter(
                                                        (item) =>
                                                          item.slug ===
                                                          formik.values
                                                            .category_slug
                                                      )[0]
                                                      .subcategories.filter(
                                                        (cat1) =>
                                                          cat1.slug ===
                                                          formik.values
                                                            .subcategory_slug
                                                      )[0].subcategory &&
                                                    categories
                                                      .filter(
                                                        (item) =>
                                                          item.slug ===
                                                          formik.values
                                                            .category_slug
                                                      )[0]
                                                      .subcategories.filter(
                                                        (cat1) =>
                                                          cat1.slug ===
                                                          formik.values
                                                            .subcategory_slug
                                                      )[0]
                                                      .subcategory.map(
                                                        (item, index) => {
                                                          return (
                                                            <option
                                                              value={item.slug}
                                                            >
                                                              {item.name}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                </SelectBox>
                                              </div>
                                              <div className="col-md-6">
                                                <SelectBox
                                                  label="Brands"
                                                  name={`brand`}
                                                  placeholder="Enter Brands"
                                                >
                                                  <option value="">
                                                    Select Brands
                                                  </option>
                                                  {brands &&
                                                    brands.map((item) => {
                                                      return (
                                                        <option
                                                          value={item.slug}
                                                        >
                                                          {item.name}
                                                        </option>
                                                      );
                                                    })}
                                                </SelectBox>
                                              </div>
                                              <div className="col-md-12">
                                                <label>Short Description</label>
                                                <EditorWYSIWYG
                                                  changeValue={(value) => {
                                                    formik.setFieldValue(
                                                      `short_description`,
                                                      value
                                                    );
                                                  }}
                                                />
                                              </div>

                                              <div className="col-md-6">
                                                <TextInput
                                                  label="Regular Price"
                                                  name={`regular_price`}
                                                  type="number"
                                                  placeholder="Enter Regular Price"
                                                />
                                              </div>
                                              <div className="col-md-6">
                                                <TextInput
                                                  label="Sale Price"
                                                  name={`sale_price`}
                                                  type="number"
                                                  placeholder="Enter Sale Price"
                                                />
                                              </div>

                                              <div className="col-md-6">
                                                <TextInput
                                                  label="Unit"
                                                  name={`units`}
                                                  type="text"
                                                  placeholder="Enter Unit"
                                                />
                                              </div>
                                              <div className="col-md-6">
                                                <TextInput
                                                  label="Stock"
                                                  name={`stock`}
                                                  type="number"
                                                  placeholder="Enter Stock"
                                                />
                                              </div>
                                              <div className="col-md-12">
                                                <label>Description</label>
                                                <EditorWYSIWYG
                                                  value={
                                                    formik.values.description
                                                  }
                                                  changeValue={(value) => {
                                                    formik.setFieldValue(
                                                      `description`,
                                                      value
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div className="col-md-6">
                                                <label> Pic </label>
                                                <div>
                                                  <input
                                                    name={`pic`}
                                                    type="file"
                                                    placeholder="Enter Stock"
                                                    multiple
                                                    onChange={(e) => {
                                                      formik.setFieldValue(
                                                        `pic`,
                                                        e.target.files
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              <FieldArray
                                                name={`product_details`}
                                              >
                                                {(anotherProps) => {
                                                  const { product_details } =
                                                    anotherProps.form.values;
                                                  return (
                                                    <div className="row">
                                                      {product_details &&
                                                        product_details.map(
                                                          (
                                                            phNumber,
                                                            number
                                                          ) => (
                                                            <div
                                                              key={number}
                                                              className="row "
                                                              style={{
                                                                borderRadius:
                                                                  "5px",
                                                                margin:
                                                                  "10px 5px",
                                                                padding:
                                                                  "10px 10px 20px 10px",
                                                                // background: `linear-gradient(to bottom, #d6fffc 0, #ffeff5 100%)`,
                                                                background: `#ffeff5`,
                                                                boxShadow: `0 3.4px 2.7px -30px rgb(0 0 0 / 6%), 0 8.2px 8.9px -30px rgb(0 0 0 / 7%), 0 25px 40px -30px rgb(0 0 0 / 20%)`,
                                                              }}
                                                            >
                                                              <div className="col-md-12">
                                                                <div className="d-flex justify-content-between">
                                                                  <div>
                                                                    <p>
                                                                      <strong>
                                                                        {" "}
                                                                        Property
                                                                        -{" "}
                                                                        {number +
                                                                          1}
                                                                      </strong>
                                                                    </p>
                                                                  </div>
                                                                  <div>
                                                                    <button
                                                                      type="button"
                                                                      className="btn btn-sm btn-danger"
                                                                      onClick={() =>
                                                                        anotherProps.remove(
                                                                          number
                                                                        )
                                                                      }
                                                                    >
                                                                      X
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-md-6">
                                                                <TextInput
                                                                  label="Lebel"
                                                                  name={`product_details[${number}][label]`}
                                                                  type="text"
                                                                  placeholder="Enter Label"
                                                                />
                                                              </div>
                                                              <div className="col-md-6">
                                                                <TextInput
                                                                  label="Value"
                                                                  name={`product_details[${number}][value]`}
                                                                  type="text"
                                                                  placeholder="Enter Value"
                                                                />
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      <div className="col-md-3 mt-3">
                                                        <button
                                                          className="btn btn-sm btn-secondary"
                                                          type="button"
                                                          onClick={() =>
                                                            anotherProps.push(
                                                              ""
                                                            )
                                                          }
                                                        >
                                                          + Add Property
                                                        </button>
                                                      </div>
                                                    </div>
                                                  );
                                                }}
                                              </FieldArray>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </FieldArray>
                                </div>
                              </div>

                              {alert &&
                                alert.map((item) => {
                                  return (
                                    <p className={`text-${item.alertType}`}>
                                      {item.msg}
                                    </p>
                                  );
                                })}
                              <div className="col-md-12 mt-3">
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  onClick={formik.handleSubmit}
                                >
                                  {formik.isSubmitting ? "Saving..." : "Save"}
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  category: state.category,
  brand: state.brand,
});

const mapDispatchToProps = { addProduct, getCategorys, getBrands };

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
