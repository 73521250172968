import React from "react";

function SingleDashboardCard({ color, title, value, icon }) {
  return (
    <div
      className={
        color ? `order-card ${color} card` : "order-card bg-c-blue card"
      }
    >
      <div className="card-body">
        <h6 className="text-white">{title}</h6>
        <h2 className="d-flex justify-content-between align-items-center text-white">
          <i className={`fa fa-${icon}`} />
          <span className=""> {value}</span>
        </h2>
        {/* <p className="d-flex justify-content-between align-items-center m-b-0">
            Completed Orders
            <span className="float-right">351</span>
          </p> */}
      </div>
    </div>
  );
}

export default SingleDashboardCard;
