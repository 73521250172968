import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <div className="pcoded-navbar menu-light ">
      <div className="navbar-wrapper">
        <div className="navbar-content next-scroll">
          <div className="scrollbar-container ps ps--active-y">
            <ul className="nav pcoded-inner-navbar    -flush">
              <li className="nav-item pcoded-menu-caption  ">
                <label className="text-capitalize">Navigation</label>
              </li>
              <li className="nav-item pcoded-hasmenu active pcoded-trigger active  ">
                <Link className="nav-link " to="/dashboard">
                  <span className="pcoded-micon">
                    <i className="fa fa-dashboard" />
                  </span>
                  <span className="pcoded-mtext">Dashboard</span>
                </Link>
              </li>
              <li className="nav-item pcoded-menu-caption  ">
                <label className="text-capitalize">Stock </label>
              </li>

              <li className="nav-item pcoded-hasmenu active pcoded-trigger active  ">
                <Link className="nav-link " to="/products">
                  <span className="pcoded-micon">
                    <i className="fa fa-shopping-cart" />
                  </span>
                  <span className="pcoded-mtext">Products</span>
                </Link>
              </li>

              <li className="nav-item pcoded-hasmenu active pcoded-trigger active  ">
                <Link className="nav-link " to="/brands">
                  <span className="pcoded-micon">
                    <i className="fa fa-cubes" />
                  </span>
                  <span className="pcoded-mtext">Brands</span>
                </Link>
              </li>
              <li className="nav-item pcoded-hasmenu active pcoded-trigger active  ">
                <Link className="nav-link " to="/categories">
                  <span className="pcoded-micon">
                    <i className="fa fa-sitemap" />
                  </span>
                  <span className="pcoded-mtext">Categories</span>
                </Link>
              </li>
              <li className="nav-item pcoded-menu-caption  ">
                <label className="text-capitalize">Sales </label>
              </li>
              <li className="nav-item pcoded-hasmenu active pcoded-trigger active  ">
                <Link className="nav-link " to="/orders">
                  <span className="pcoded-micon">
                    <i className="fa fa-money" />
                  </span>
                  <span className="pcoded-mtext">Orders</span>
                </Link>
              </li>
              <li className="nav-item pcoded-menu-caption  ">
                <label className="text-capitalize">Discount </label>
              </li>
              <li className="nav-item pcoded-hasmenu active pcoded-trigger active  ">
                <Link className="nav-link " to="/coupons">
                  <span className="pcoded-micon">
                    <i className="fa fa-shopping-bag" />
                  </span>
                  <span className="pcoded-mtext">Coupons</span>
                </Link>
              </li>
              {/* <li className="nav-item pcoded-menu-caption  ">
                <label className="text-capitalize">User </label>
              </li>
              <li className="nav-item pcoded-hasmenu active pcoded-trigger active  ">
                <Link className="nav-link " to="/customers">
                  <span className="pcoded-micon">
                    <i className="fa fa-users" />
                  </span>
                  <span className="pcoded-mtext">Customers</span>
                </Link>
              </li> */}

              <li className="nav-item pcoded-menu-caption  ">
                <label className="text-capitalize">Settings </label>
              </li>
              <li className="nav-item pcoded-hasmenu active pcoded-trigger active  ">
                <Link className="nav-link " to="/pincodes">
                  <span className="pcoded-micon">
                    <i className="fa fa-map" />
                  </span>
                  <span className="pcoded-mtext">Pincode</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
