import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import SingleDashboardCard from "../components/SingleDashboardCard";
import { getOrders, countOrder } from "../actions/orders";
import { getProducts, countProduct } from "../actions/products";
import { countCustomer } from "../actions/cutomers";
import { getBrands } from "../actions/brands";

import { Link } from "react-router-dom";

const Dashboard = ({
  getOrders,
  countOrder,
  getProducts,
  countProduct,
  countCustomer,

  getBrands,
  order: { orders, total_orders },
  brand: { brands },
  product: { products, total_products },
  customer: { total_customers },
}) => {
  useEffect(() => {
    getOrders();
    countOrder();
    getProducts();
    countProduct();
    getBrands();
    countCustomer();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard</title>
      </Helmet>
      <Sidebar />
      <Navbar />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  <SingleDashboardCard
                    color="bg-c-blue"
                    title="Orders "
                    value={total_orders}
                    icon="money"
                  />
                </div>
                <div className="col-xl-3 col-md-6">
                  <SingleDashboardCard
                    color="bg-c-green"
                    title="Products "
                    value={total_products}
                    icon="money"
                  />
                </div>
                <div className="col-xl-3 col-md-6">
                  <SingleDashboardCard
                    color="bg-c-yellow"
                    title="Customers "
                    icon="money"
                    value={total_customers}
                  />
                </div>
                <div className="col-xl-3 col-md-6">
                  <SingleDashboardCard
                    color="bg-c-red"
                    title="Brands "
                    value={brands && brands.length}
                    icon="money"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Recent Orders</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-card">
                        <div className="">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>
                                    <span>#</span>
                                  </th>
                                  <th>
                                    <span>Amount Total</span>
                                  </th>
                                  <th>
                                    <span>Customer</span>
                                  </th>
                                  <th>
                                    <span>Pin</span>
                                  </th>
                                  <th>
                                    <span>Status</span>
                                  </th>
                                  <th>
                                    <span>Action</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders &&
                                  orders.map((item, index) => {
                                    return (
                                      <tr key={item._id}>
                                        <td> {index + 1} </td>
                                        <td>{item.total_amount}</td>
                                        <td>
                                          {item.customer &&
                                            item.customer.first_name}{" "}
                                          {item.customer &&
                                            item.customer.last_name}
                                        </td>
                                        <td>
                                          {item.address && item.address.zipcode}
                                        </td>
                                        <td> {item.order_status} </td>

                                        <td>
                                          <Link
                                            to={`/orders/${item._id}/view`}
                                            className="btn btn-sm btn-primary"
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                          <Link
                                            to={`/orders/${item._id}/edit`}
                                            className="btn btn-sm btn-secondary m-1"
                                          >
                                            <i className="fa fa-edit"></i>
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                            <div className="text-center">
                              <Link to="/orders" className="btn btn-primary">
                                {" "}
                                View All{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Recent Products</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-card">
                        <div className="">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>
                                    <span>#</span>
                                  </th>
                                  <th>
                                    <span>Price</span>
                                  </th>
                                  <th>
                                    <span>Name</span>
                                  </th>
                                  <th>
                                    <span>Category</span>
                                  </th>
                                  <th>
                                    <span>Action</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {products &&
                                  products.map((item, index) => {
                                    if (index < 10)
                                      return (
                                        <tr key={item._id}>
                                          <td> {index + 1} </td>
                                          <td>{item.regular_price}</td>
                                          <td>{item.name}</td>
                                          <td>{item.category_slug}</td>

                                          <td>
                                            <Link
                                              to={`/products/${item._id}/view`}
                                              className="btn btn-sm btn-primary"
                                            >
                                              <i className="fa fa-eye"></i>
                                            </Link>
                                            <Link
                                              to={`/products/${item._id}/edit`}
                                              className="btn btn-sm btn-warning m-1"
                                            >
                                              <i className="fa fa-edit"></i>
                                            </Link>
                                          </td>
                                        </tr>
                                      );
                                  })}
                              </tbody>
                            </table>
                            <div className="text-center">
                              <Link to="/products" className="btn btn-primary">
                                {" "}
                                View All{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  order: state.order,
  product: state.product,
  customer: state.customer,
  brand: state.brand,
});

const mapDispatchToProps = {
  getOrders,
  countOrder,
  getProducts,
  getBrands,
  countProduct,
  countCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
