import {
  GET_ZIPCODES,
  TOTAL_ZIPCODES,
  ADD_ZIPCODE,
  EDIT_ZIPCODE,
  GET_ZIPCODE,
  ZIPCODES_ERROR,
} from "../actions/types";

const initialState = {
  zipcodes: null,
  zipcode: null,
  total_zipcodes: 0,
  loading: true,
  error: {},
  zipcode_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ZIPCODES:
      return {
        ...state,
        zipcodes: payload,
        loading: false,
        error: {},
      };

    case TOTAL_ZIPCODES:
      return {
        ...state,
        total_zipcodes: payload,
        loading: false,
        error: {},
      };

    case ADD_ZIPCODE:
      return {
        ...state,
        zipcode_message: payload,
        loading: false,
        error: {},
      };
    case GET_ZIPCODE:
      return {
        ...state,
        zipcode: payload,
        loading: false,
        error: {},
      };
    case EDIT_ZIPCODE:
      return {
        ...state,
        zipcode_message: payload,
        loading: false,
        error: {},
      };

    case ZIPCODES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
